<template>
    <div class="col-right">
        <a-spin :spinning="spinning">
            <div style="display: flex;">
                <div style="height: 160px; width: 240px; background-color: #ffffff; padding: 15px 14px;"><h3
                        style="font-weight: bold; margin-bottom: 2.5em;">待开票金额</h3>
                    <p style="text-align: left; margin: 0; font-size: 28px; font-weight: 500; color: #DE0000;">￥{{pendingSettleAmount}}</p>
                </div>
                <div style="flex: 1; padding: 10px 17px 0; background-color: #ffffff; margin-left: 10px;"><h3
                        style="font-weight: bold; margin-bottom: 5px;">开票规则</h3>
                    <p style="margin: 0;">1、每月1号自动生成上月的账单；</p>
                    <p style="margin: 0;">2、若商品购买订单已完成、订单无未结束的关联退款单、订单超过售后期限，则可计入账单；</p>
                    <p style="margin: 0;">3、若商品退款单已完成，则可计入账单；</p>
                    <p style="margin: 0;">4、若当月账单金额为负，则需向平台补票；</p>
                    <p style="margin: 0;">5、电子发票一般需1-3个工作日处理开票并告知，纸质发票一般需3-5个工作日处理开票并寄出。</p></div>
            </div>
            <a-card title="发票帐单" style="margin-top: 16px;">
                <a-table :columns="columns" :data-source="invoiceList" :pagination="false">
                    <a slot="periods" slot-scope="text, record" @click="$jumpTo('/buyer/bill/'+record.id)">
                        {{record.billStartTime}}至<br /> {{record.billEndTime}}
                    </a>
                    <div slot="action" slot-scope="text, record, idx">
                        <a v-if="record.distributorInvoiceStatus== 0 && record.billAmount > 0" @click="showInvoiceApply(idx)">申请开票</a>
                        <a v-else-if="record.distributorInvoiceStatus== 1" @click="invoiceDetail(idx)">开票详情</a>
                        <a v-else-if="record.distributorInvoiceStatus== 2" @click="invoiceDetail(idx)">开票详情</a>
                    </div>
                </a-table>
                <div class="pagination">
                    <a-pagination v-model="queryParams.page" :pageSize="queryParams.pageSize" :total="total" show-less-items @change="changePage" />
                </div>
            </a-card>
        <a-modal
            :dialog-style="{top: '100px'}"
            width="480px"
            :visible="showInvoiceApplyModal"
            :footer="false"
            @cancel="() => {showInvoiceApplyModal = false}"
        >
            <h2 slot="title" class="text-center">申请发票</h2>
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" :form="form" @submit="handleSubmit">
                <a-form-item label="发票金额">
                    <div>￥{{ invoiceLogDetail.billAmount }}</div>
                </a-form-item>
                <a-form-item label="票据类型">
                    <a-select
                        v-decorator="['billType',rules.billType,]"
                        placeholder="请选择"
                    >
                        <a-select-option value="1">
                          纸质发票
                        </a-select-option>
                        <a-select-option value="2">
                          电子发票
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="发票类型">
                    <a-select
                        v-decorator="['invoiceType',rules.invoiceType,]"
                        placeholder="请选择"
                    >
                        <a-select-option value="1">
                          专用发票
                        </a-select-option>
                        <a-select-option value="2">
                          普通发票
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="发票抬头">
                    <div>{{invoiceInfo.invoice_title}}</div>
                </a-form-item>
                <a-form-item label="纳税人识别号">
                    <div>{{ invoiceInfo.taxpayer_iden_num }}</div>
                </a-form-item>
                <a-form-item label="纳税人类型" required>
                    <div>{{ invoiceInfo.taxpayer_type_desc }}</div>
                </a-form-item>
                <a-form-item label="注册地址">
                    <div>{{ invoiceInfo.register_addr }}</div>
                </a-form-item>
                <a-form-item label="注册电话">
                    <div>{{ invoiceInfo.register_phone }}</div>
                </a-form-item>
                <a-form-item label="开户银行">
                    <div>{{ invoiceInfo.bank_open_name }}</div>
                </a-form-item>
                <a-form-item label="收票人">
                    <div>{{ invoiceInfo.title_collector }}</div>
                </a-form-item>
                <a-form-item label="手机号码">
                    <div>{{ invoiceInfo.phone }}</div>
                </a-form-item>
                <a-form-item label="所在地区">
                    <div>{{ invoiceInfo.ticket_region }}</div>
                </a-form-item>
                <a-form-item label="详细地址">
                    <div>{{ invoiceInfo.detail_address }}</div>
                </a-form-item>
                <a-form-item label="电子邮箱">
                    <div>{{ invoiceInfo.email }}</div>
                </a-form-item>
                    <a-row>
                        <a-col :offset="6">
                            <a-button @click="() => {showInvoiceApplyModal = false;}">取消</a-button>
                                <a-button style="margin-left: 10px;" type="primary" html-type="submit">保存</a-button>
                        </a-col>
                    </a-row>
            </a-form>

        </a-modal>
        <a-modal
            :dialog-style="{ top: '100px' }"
            width="480px"
            :visible="showInvoiceDetail"
            :footer="false"
            @cancel="() => {showInvoiceDetail = false}"
        >
            <h2 slot="title" class="text-center">发票详情</h2>
            <p class="text-center color-success"><a-icon type="clock-circle" theme="filled" /> {{ invoiceLogDetail.distributorInvoiceStatusDesc }}</p>
            <p class="text-center" v-if="invoiceLogDetail.distributorInvoiceStatus == 2">纸质发票寄送需要3-5个工作日；电子发票可查看下载发票</p>
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" >
                <a-form-item label="发票号码" v-if="invoiceLogDetail.distributorInvoiceStatus == 2">
                    <a-popover v-model="showInvoicePopover">
                    <template slot="content">
                      <a-table :columns="detailColumns" :data-source="invoiceLogDetail.detail" :pagination="false" :bordered="true">
                        <a slot="num" slot-scope="text, record" @click="showImg(record.img)">{{ text }}</a>
                      </a-table>
                    </template>
                     <a>{{invoiceLogDetail.detail.length}}张发票</a>
                  </a-popover>
                </a-form-item>
                <a-form-item label="发票金额">
                    <div>￥{{ invoiceLogDetail.billAmount }}</div>
                </a-form-item>
                <a-form-item label="票据类型" required>
                    <div>{{ invoiceLogDetail.billTypeDesc }}</div>
                </a-form-item>
                <a-form-item label="发票类型" required>
                    <div>{{ invoiceLogDetail.invoiceTypeDesc }}</div>
                </a-form-item>
                <a-form-item label="发票抬头">
                    <div>{{ invoiceLogDetail.invoiceTitle }}</div>
                </a-form-item>
                <a-form-item label="纳税人识别号">
                    <div>{{ invoiceLogDetail.taxpayerIdenNum }}</div>
                </a-form-item>
                <a-form-item label="纳税人类型" required>
                    <div>{{ invoiceLogDetail.taxpayerTypeDesc }}</div>
                </a-form-item>
                <a-form-item label="注册地址">
                    <div>{{ invoiceLogDetail.registeredAddr }}</div>
                </a-form-item>
                <a-form-item label="注册电话">
                    <div>{{invoiceLogDetail.registeredPhone}}</div>
                </a-form-item>
                <a-form-item label="开户银行">
                    <div>{{ invoiceLogDetail.bankOpenName }}</div>
                </a-form-item>
                <a-form-item label="收票人">
                    <div>{{ invoiceLogDetail.ticketCollector }}</div>
                </a-form-item>
                <a-form-item label="手机号码">
                    <div>{{ invoiceLogDetail.phone }}</div>
                </a-form-item>
                <a-form-item label="所在地区">
                    <div>{{ invoiceLogDetail.ticketRegion }}</div>
                </a-form-item>
                <a-form-item label="详细地址">
                    <div>{{ invoiceLogDetail.detailedAddress }}</div>
                </a-form-item>
                <a-form-item label="电子邮箱">
                    <div>{{ invoiceLogDetail.ticketEmail }}</div>
                </a-form-item>
                <a-row v-if="invoiceLogDetail.distributorInvoiceStatus == 1">
                        <a-col :offset="6">
                            <a-button @click="() => {showInvoiceDetail = false;}">取消</a-button>
                                <a-button style="margin-left: 10px;" type="primary" @click="modifyApply(invoiceLogDetail.id)">修改开票申请</a-button>
                        </a-col>
                    </a-row>
            </a-form>
        </a-modal>
            <a-modal
                    :dialog-style="{ top: '100px' }"
                    width="480px"
                    :visible="showImgModal"
                    :footer="false"
                    @cancel="() => {showImgModal = false}"
            >
                <div slot="title">查看图片</div>
                <img style="width:100%" :src="invoiceImg" alt="">
            </a-modal>

        </a-spin>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    export default {
        name: "property-invoice",
        data() {
            return {
                spinning:false,
                pendingSettleAmount:0,
                columns: [
                    {
                        title: '帐单周期',
                        dataIndex: 'periods',
                        key: 'periods',
                        scopedSlots: { customRender: 'periods' },
                        width: '200px',
                    },
                    {
                        title: '帐单编号',
                        dataIndex: 'billNo',
                        key: 'billNo',
                    },
                    {
                        title: '可开票金额(元)',
                        dataIndex: 'billAmount',
                        key: 'billAmount',
                    },
                    {
                        title: '票据类型',
                        dataIndex: 'billTypeDesc',
                        key: 'billTypeDesc',
                    },
                    {
                        title: '发票类型',
                        dataIndex: 'invoiceTypeDesc',
                        key: 'invoiceTypeDesc',
                    },
                    {
                        title: '发票抬头',
                        dataIndex: 'invoiceTitle',
                        key: 'invoiceTitle',
                    },
                    {
                        title: '状态',
                        dataIndex: 'distributorInvoiceStatusDesc',
                        key: 'distributorInvoiceStatusDesc',
                        width: '80px'
                    },
                    {
                        title: '操作',
                        key: 'action',
                        scopedSlots: { customRender: 'action' },
                        width: '90px'
                    },
                ],
                detailColumns:[
                    {
                        title:'发票号码',
                        dataIndex: 'num',
                        key: 'num',
                        scopedSlots: { customRender: 'num' },
                    },
                    {
                        title: '发票金额',
                        dataIndex: 'amount',
                        key: 'amount',
                    },
                    {
                        title: '发票类型',
                        dataIndex: 'billType',
                        key: 'billType',
                        scopedSlots: { customRender: 'billType' },
                    },
                    {
                        title: '票据类型',
                        dataIndex: 'invoiceType',
                        key: 'invoiceType',
                        scopedSlots: { customRender: 'invoiceType ' },
                    },
                ],
                total:0,
                invoiceList:[],
                showInvoiceDetail: false,
                showInvoiceApplyModal: false,
                showInvoicePopover: false,
                showImgModal: false,
                queryParams:{
                    page: 1,
                    pateSize:10,
                },
                invoiceInfo:{},
                invoiceLogDetail:{
                    billType:0,
                    invoiceType:0
                },
                rules:{
                    billType:{rules:[{ required: true, message:'请选择'}]},
                    invoiceType:{rules:[{ required: true, message:'请选择'}]},
                },
                invoiceImg:''
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'register'});
        },
        created() {
            this.getList();
            this.getInvoiceTitle();
            this.pendingSettle();
        },
        methods: {
            showImg(img){
                console.log(img);
                this.showInvoicePopover = false;
                this.showInvoiceDetail = false;
                this.showImgModal = true;
                this.invoiceImg = img;
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    console.log(values)
                    if (!err) {

                        values.id = this.invoiceLogDetail.id;
                        service.post(service.uri.invoice.apply, values).then(res => {
                            this.showInvoiceApplyModal = false;
                            if(res.code == 200){
                                this.$message.success("保存成功");

                                this.getList();
                            }
                        });
                    }
                });
            },
            getInvoiceTitle(){
                this.spinning = true;
                service.post(service.uri.invoice.getInfo).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.invoiceInfo = res.data;
                    }
                });
            },
            pendingSettle(){
                this.spinning = true;
                service.post(service.uri.invoice.pendingSettle).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.pendingSettleAmount = res.data;
                    }
                });
            },
            invoiceDetail(idx){
                this.showInvoiceDetail = true;
                this.invoiceLogDetail = this.invoiceList[idx];
            },
            showInvoiceApply(idx){
                this.showInvoiceApplyModal = true;
                this.invoiceLogDetail = this.invoiceList[idx];
            },
            modifyApply(){
                this.showInvoiceDetail = false;
                this.showInvoiceApplyModal = true;
            },
            changePage(e){
                this.queryParams.page = e;
                this.getList();
            },
            getList(){
                this.spinning = true;
                service.post(service.uri.invoice.getLog, this.queryParams).then(res => {
                    this.spinning = false;
                    if(res.code == 200){
                        this.invoiceList = res.data.list;
                        this.total = res.data.total;
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.ant-form-item {
    margin-bottom: 0;
}
</style>